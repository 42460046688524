import React from "react";
import {
  ProductItemContainer,
  ProductItemContent,
  ProductItemDescription,
  ProductItemDetail,
  ProductItemDetails,
  ProductItemFlag,
  ProductItemPrice,
  ProductItemShape,
  ProductItemTitle,
} from "./ProductItemElements";
import ReactCountryFlag from "react-country-flag";
import { BsCheckLg } from "react-icons/bs";

const ProductItem = ({
  productTitle,
  productAnnually,
  productDescription,
  productEnable,
  productMonthly,
  productQuarterly,
  productSemiannual,
  productTraffic,
  ProductItemBold,
  productCountry,
}) => {
  return (
    <ProductItemContainer>
      <ProductItemContent bold={ProductItemBold}>
        {ProductItemBold ? (
          <ProductItemShape>
            {(() => {
              const shapes = [];
              for (let i = 0; i < 10; i++) {
                shapes.push(<li key={i}></li>);
              }
              return shapes;
            })()}
          </ProductItemShape>
        ) : (
          ""
        )}
        <ProductItemFlag bold={ProductItemBold}>
          <ReactCountryFlag
            className="emojiFlag"
            countryCode={productCountry}
            style={{
              fontSize: "2em",
              lineHeight: "1em",
              position: "relative",
              top: "6px",
            }}
          />
        </ProductItemFlag>
        <ProductItemTitle bold={ProductItemBold}>
          {productTitle}
        </ProductItemTitle>
        <ProductItemDescription bold={ProductItemBold}>
          {productDescription}
        </ProductItemDescription>
        <ProductItemDetails bold={ProductItemBold}>
          <ProductItemDetail bold={ProductItemBold}>
            <BsCheckLg /> وضعیت : {productEnable === 1 ? "فعال" : "غیر فعال"}
          </ProductItemDetail>
          <ProductItemDetail bold={ProductItemBold}>
            <BsCheckLg />
            ترافیک :{" "}
            {productTraffic === null ? "نامحدود" : `${productTraffic}GB`}
          </ProductItemDetail>
          {productMonthly !== null ? (
            <ProductItemDetail bold={ProductItemBold}>
              <BsCheckLg />
              ماهانه :{" "}
              {Number(Number(productMonthly).toFixed(1)).toLocaleString()} تومان
            </ProductItemDetail>
          ) : (
            ""
          )}
          {productQuarterly !== null ? (
            <ProductItemDetail bold={ProductItemBold}>
              <BsCheckLg />3 ماهه :{" "}
              {Number(Number(productQuarterly).toFixed(1)).toLocaleString()}{" "}
              تومان
            </ProductItemDetail>
          ) : (
            ""
          )}
          {productSemiannual !== null ? (
            <ProductItemDetail bold={ProductItemBold}>
              <BsCheckLg />6 ماهه :{" "}
              {Number(Number(productSemiannual).toFixed(1)).toLocaleString()}{" "}
              تومان
            </ProductItemDetail>
          ) : (
            ""
          )}
          {productAnnually !== null ? (
            <ProductItemDetail bold={ProductItemBold}>
              <BsCheckLg />
              سالانه :{" "}
              {Number(Number(productAnnually).toFixed(1)).toLocaleString()}{" "}
              تومان
            </ProductItemDetail>
          ) : (
            ""
          )}
        </ProductItemDetails>
      </ProductItemContent>
      <ProductItemPrice bold={ProductItemBold}>
        {Number(Number(productMonthly).toFixed(1)).toLocaleString()} تومان
      </ProductItemPrice>
    </ProductItemContainer>
  );
};

export default ProductItem;
