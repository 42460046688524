import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import LoginOptionPage from "./pages/LoginOptionPage";
// import LoginOtpPage from "./pages/LoginOtpPage";
import LoginUsernamePage from "./pages/LoginUsernamePage";
import NotFoundPage from "./pages/NotFoundPage";
import axios from "axios";
import Cookies from "universal-cookie";
import Home from "./components/Home";
import News from "./components/News";
import Profile from "./components/Profile";
import Products from "./components/Products";
import "./firebase";
import ApiErrorPage from "./components/ApiError";

export const cookies = new Cookies();

const App = () => {
  const [tokenValidation, setTokenValidation] = useState(false);
  const [loading, setLoading] = useState(true);
  const [apiError, setApiError] = useState(false);
  const [profileState, SetProfiletState] = useState(null);
  const [dashboardState, SetDashboardState] = useState(false);
  const [connectionState, SetConnectionState] = useState(false);
  const [newsState, SetNewsState] = useState(false);
  const [productsState, SetProductsState] = useState(false);
  const [lastPage, setLastPage] = useState(1);
  const [notifactionCount, setNotifactionCount] = useState(false);
  const [userInvoice, setUserInvoice] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [category, setCategory] = useState(0);
  const [networkError, setNetworkError] = useState(false);
  const [notifactionState, setNotifactionState] = useState(null);
  const [reseller, setReseller] = useState(false);

  useEffect(() => {
    if (profileState === null) {
      const options = {
        method: "GET",
        url: `https://jetup.app/api/user/profile${
          cookies.get("firebase-token") !== undefined
            ? `?fcm${cookies.get("firebase-token")}`
            : ""
        }`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${cookies.get("token")}`,
        },
      };
      axios
        .request(options)
        .then((response) => {
          if (response.status === 200) {
            setReseller(response.data.reseller);
            SetProfiletState({ ...response.data });
            setTokenValidation(true);
            setLoading(false);
            setApiError(false);
          }
          if (response.status === 401) {
            cookies.remove("token");
            setTokenValidation(false);
            setLoading(false);
            setApiError(false);
          }
        })
        .catch((error) => {
          if (error.message === "Request failed with status code 401") {
            cookies.remove("token");
            setTokenValidation(false);
            setLoading(false);
            setApiError(false);
          } else if (error.message === "Network Error") {
            setNetworkError(true);
            setLoading(false);
            console.log(error);
          } else {
            setLoading(false);
            setApiError(true);
            console.log(error);
          }
        });
    }
  });
  const handelTokenValidation = () => {
    setTokenValidation(true);
  };
  const handelLogout = () => {
    cookies.remove("token");
    setTokenValidation(false);
    setApiError(false);
    SetProfiletState(null);
    SetDashboardState(false);
    SetConnectionState(false);
    SetNewsState(false);
    SetProductsState(false);
    setLastPage(1);
    setNotifactionCount(false);
    setUserInvoice(0);
    setActiveIndex(0);
    setCategory(0);
    setLoading(true);
    setNotifactionState(null);
  };
  if (networkError === false) {
    return (
      <Router basename="/">
        <Routes>
          <Route
            path="/"
            element={
              <LoginOptionPage
                tokenValidation={tokenValidation}
                loadingPage={loading}
                apiError={apiError}
              />
            }
          />
          <Route
            path="login-username"
            element={
              <LoginUsernamePage
                tokenValidation={tokenValidation}
                apiError={apiError}
                loadingPage={loading}
                handelTokenValidation={() => {
                  handelTokenValidation();
                }}
              />
            }
          />
          {/* <Route
            path="login-otp"
            element={
              <LoginOtpPage
                apiError={apiError}
                tokenValidation={tokenValidation}
                loadingPage={loading}
              />
            }
          /> */}
          <Route
            path="dashboard"
            element={
              <Dashboard
                tokenValidation={tokenValidation}
                apiError={apiError}
                loadingPage={loading}
              />
            }
          >
            <Route
              index
              element={
                <Home
                  dashboardState={dashboardState}
                  SetDashboardState={SetDashboardState}
                  connectionState={connectionState}
                  SetConnectionState={SetConnectionState}
                  lastPage={lastPage}
                  setLastPage={setLastPage}
                  notifactionCount={notifactionCount}
                  setNotifactionCount={setNotifactionCount}
                  userInvoice={userInvoice}
                  setUserInvoice={setUserInvoice}
                  tokenValidation={tokenValidation}
                  notifactionState={notifactionState}
                  handelLogout={handelLogout}
                  setNotifactionState={setNotifactionState}
                  reseller={reseller}
                />
              }
            />
            <Route
              path="products"
              element={
                <Products
                  productsState={productsState}
                  SetProductsState={SetProductsState}
                  dashboardState={dashboardState}
                  SetDashboardState={SetDashboardState}
                  SetConnectionState={SetConnectionState}
                  notifactionCount={notifactionCount}
                  setNotifactionCount={setNotifactionCount}
                  activeIndex={activeIndex}
                  handelLogout={handelLogout}
                  setActiveIndex={setActiveIndex}
                  category={category}
                  tokenValidation={tokenValidation}
                  setCategory={setCategory}
                  notifactionState={notifactionState}
                  setNotifactionState={setNotifactionState}
                />
              }
            />
            <Route
              path="news"
              element={
                <News
                  newsState={newsState}
                  SetNewsState={SetNewsState}
                  dashboardState={dashboardState}
                  SetDashboardState={SetDashboardState}
                  SetConnectionState={SetConnectionState}
                  notifactionCount={notifactionCount}
                  handelLogout={handelLogout}
                  tokenValidation={tokenValidation}
                  setNotifactionCount={setNotifactionCount}
                  notifactionState={notifactionState}
                  setNotifactionState={setNotifactionState}
                />
              }
            />
            <Route
              path="profile"
              element={
                <Profile
                  profileState={profileState}
                  dashboardState={dashboardState}
                  SetDashboardState={SetDashboardState}
                  SetConnectionState={SetConnectionState}
                  handelLogout={handelLogout}
                  notifactionCount={notifactionCount}
                  tokenValidation={tokenValidation}
                  setNotifactionCount={setNotifactionCount}
                  notifactionState={notifactionState}
                  setNotifactionState={setNotifactionState}
                />
              }
            />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    );
  } else {
    return (
      <ApiErrorPage
        errorText={"لطفا از اتصال دستگاه خود به اینترنت مطمئن شوید."}
      />
    );
  }
};

export default App;
