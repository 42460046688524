import React from "react";
import { LoadingPageContainer } from "./LoadingPageElements";

const LoadingPage = () => {
  return (
    <LoadingPageContainer>
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </LoadingPageContainer>
  );
};

export default LoadingPage;
