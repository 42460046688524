import React, { useEffect } from "react";
import NotFound from "../components/NotFound";

const NotFoundPage = () => {
  useEffect(() => {
    document.title = "صفحه مورد نظر یافت نشد";
  });
  return <NotFound />;
};

export default NotFoundPage;
