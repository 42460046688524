import React from "react";
import ContentLoader from "react-content-loader";

const LazyLoadText = () => {
  return (
    <ContentLoader
      width="65px"
      height="20px"
      viewBox="0 0 200 60"
      backgroundColor="#ffffff4e"
      foregroundColor="#ecebebc7"
      style={{
        position: "relative",
        zIndex: 5,
        verticalAlign: "top",
        display: "inline",
      }}
    >
      <rect x="36" y="20" rx="0" ry="0" width="200" height="33" />
    </ContentLoader>
  );
};

export default LazyLoadText;
