import styled from "styled-components";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

export const LoginUsernameContainer = styled.section`
  width: 575px;
  max-width: 100%;
  padding: 0 24px;
  margin: 0 auto;
  height: 100vh;
  overflow: hidden;
  @media screen and (max-height: 600px) {
    height: auto;
    overflow: unset;
  }
`;

export const LoginUsernameImg = styled.img`
  display: block;
  width: 400px;
  object-fit: contain;
  margin: 40px auto;
  @media screen and (min-width: 992px) {
    margin-top: 80px;
  }
  @media screen and (max-width: 768px) {
    height: 200px;
    width: 100%;
  }
`;
export const LoginUsernameAlert = styled.p`
  padding: 10px;
  margin: 10px 0;
  font-size: 14px;
  border-right: 4px solid #eb2f2f;
  background-color: #ee707021;
  color: #eb2f2f;
`;
export const LoginUsernameTitle = styled.h3`
  font-size: 2rem;
  margin-bottom: 20px;
`;

export const LoginUsernameFrom = styled.form`
  position: relative;
  margin-bottom: 10px;
`;

export const LoginUsernameInputContainer = styled.div`
  margin-bottom: 25px;
`;

export const LoginUsernameInput = styled.input`
  display: block;
  width: 100%;
  font-family: "yekan";
  padding: 14px;
  border: none;
  border-bottom: 2px solid #b8b8b8;
  border-radius: 0;
  &:focus {
    border-bottom: 2px solid #0165ff;
  }
  @media screen and (min-width: 395px) {
    font-size: 0.95rem;
  }
`;

export const LoadingIcon = styled(AiOutlineLoading3Quarters)`
  width: 20px;
  height: auto;
  -webkit-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
`;
