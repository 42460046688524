import React, { useEffect, useState } from "react";
import Menu from "../Menu";
import { NewsContainer, NewsError, NewsItem } from "./NewsElements";
import Cookies from "universal-cookie";
import axios from "axios";
import PullToRefresh from "react-simple-pull-to-refresh";
import { Markup } from "interweave";
import ContentLoader from "react-content-loader";
import { HomeError } from "../Home/HomeElements";

const cookies = new Cookies();

const News = ({
  newsState,
  SetNewsState,
  dashboardState,
  SetDashboardState,
  SetConnectionState,
  notifactionCount,
  setNotifactionCount,
  tokenValidation,
  notifactionState,
  setNotifactionState,
  handelLogout,
}) => {
  const [state, setState] = useState(newsState);
  const [dashNumber, setDashNumber] = useState(0);
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    document.title = "اخبار";
    handelConnections();
    if (state === false) {
      handleRequest();
    }
  });
  const handelConnections = () => {
    if (
      dashboardState !== false &&
      dashNumber === 0 &&
      dashboardState !== null
    ) {
      setDashNumber(1);
      const dashboardItems = { data: [...dashboardState.data] };
      SetDashboardState({ data: [...dashboardItems.data.slice(0, 20)] });
      SetConnectionState({ data: [...dashboardItems.data.slice(0, 20)] });
    }
  };
  const handleRequest = () => {
    setState(false);
    SetNewsState(false);
    const options = {
      method: "GET",
      url: "https://jetup.app/api/blog/posts",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${cookies.get("token")}`,
      },
    };
    axios
      .request(options)
      .then((response) => {
        if (response.status === 200) {
          setState({ data: [...response.data.data] });
          SetNewsState({ data: [...response.data.data] });
        }
      })
      .catch((error) => {
        if (error.response !== undefined) {
          console.log(error.response);
          if (error.response.status === 401) {
            handelLogOutMenu();
          }
          if (error.response.status === 500) {
            setState(null);
            SetNewsState(false);
            setErrorText(
              "مشکلی در دریافت اطلاعات پیش آمده ، دقایقی دیگر مجددا تلاش کنید."
            );
          }
        }
        if (error.message === "Network Error") {
          setState(null);
          SetNewsState(false);
          setErrorText("لطفا از اتصال اینترنت خود مطمئن شوید.");
        }
      });
  };
  const handelLogOutMenu = () => {
    cookies.remove("token", { path: "/" });
    let IntervalChecking = setInterval(() => {
      if (typeof handelLogout === "function") {
        handelLogout();
        clearInterval(IntervalChecking);
      } else {
        console.log("handel logout is not function yet :||| ");
      }
    }, 1000);
  };
  return (
    <PullToRefresh
      onRefresh={() => new Promise((resolve) => resolve(handleRequest()))}
      isPullable={true}
      canFetchMore={true}
      pullingContent=""
      maxPullDownDistance={100}
      pullDownThreshold={100}
    >
      <Menu
        notifactionCount={notifactionCount}
        setNotifactionCount={setNotifactionCount}
        tokenValidation={tokenValidation}
        notifactionState={notifactionState}
        setNotifactionState={setNotifactionState}
      />
      <NewsContainer>
        {state === null ? (
          <HomeError
            style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 70 }}
          >
            {errorText}
          </HomeError>
        ) : (
          <>
            {state === null ? (
              <NewsError>در حال حاظر خبری برای نمایش وجود ندارد :(</NewsError>
            ) : (
              <>
                {state !== false ? (
                  state.data.map((e, i) => (
                    <NewsItem key={i}>
                      <Markup content={e.content} />
                    </NewsItem>
                  ))
                ) : (
                  <ContentLoader
                    width="100%"
                    height="100%"
                    viewBox="0 0 450 800"
                    backgroundColor="#d6d6d6bc"
                    foregroundColor="#dfdfdfaf"
                  >
                    <rect
                      x="0"
                      y="10"
                      rx="20"
                      ry="20"
                      width="100%"
                      height="237"
                    />

                    <rect
                      x="0"
                      y="280"
                      rx="20"
                      ry="20"
                      width="100%"
                      height="237"
                    />

                    <rect
                      x="0"
                      y="560"
                      rx="20"
                      ry="20"
                      width="100%"
                      height="237"
                    />
                  </ContentLoader>
                )}
              </>
            )}
          </>
        )}
      </NewsContainer>
    </PullToRefresh>
  );
};

export default News;
