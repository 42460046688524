import styled from "styled-components";

export const ConnectionsContainer = styled.div`
  height: ${({ moreDetails }) => (moreDetails ? "100%" : "61px")};
  background-color: ${({ expired }) => (expired ? "#ffc4c4" : "#fff")};
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-top: 20px;
  border-radius: 15px;
  overflow: hidden;
  ${({ infinity }) =>
    infinity &&
    `
    background: #EEE8AA;
  `}
`;
export const ConnectionShow = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
  overflow: hidden;
`;
export const ConnectionName = styled.h5`
  max-width: 105px;
  font-size: 0.8rem;
  margin-left: auto;
  padding: 7px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media screen and (min-width: 395px) {
    font-size: 0.95rem;
    max-width: 130px;
  }
`;
export const ConnectionErrorText = styled.p`
  background-color: #e501012c;
  color: #e50101;
  padding: 2px 7px;
  font-size: 0.7rem;
  border-radius: 8px;
`;
export const ConnectionCopyText = styled.button`
  position: relative;
  display: inline-flex;
  justify-content: center;
  flex-flow: column wrap;
  align-content: center;
  min-width: 75px;
  height: 32px;
  color: #2e2e2e;
  background-color: #ececec;
  font-size: 0.9rem;
  text-align: center;
  padding: 5px;
  border-radius: 8px;
  cursor: pointer;
  z-index: 5;
  svg {
    margin-left: 6px;
    vertical-align: middle;
  }
`;
export const ConnectionCopyQrCode = styled.button`
  position: relative;
  display: inline-flex;
  justify-content: center;
  flex-flow: column wrap;
  align-content: center;
  height: 32px;
  width: 32px;
  color: #2e2e2e;
  background-color: #ececec;
  font-size: 0.9rem;
  text-align: center;
  padding: 5px;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 6px;
  z-index: 5;
`;
export const ConnectionShare = styled.button`
  position: relative;
  display: inline-flex;
  justify-content: center;
  flex-flow: column wrap;
  align-content: center;
  height: 32px;
  width: 32px;
  color: #2e2e2e;
  background-color: #ececec;
  font-size: 0.9rem;
  text-align: center;
  padding: 5px;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 6px;
  z-index: 5;
`;
export const ConnectionShowMoreDetails = styled.button`
  display: inline-flex;
  justify-content: center;
  align-content: center;
  flex-flow: column wrap;
  height: 32px;
  width: 32px;
  color: #2e2e2e;
  background-color: #ececec;
  font-size: 0.9rem;
  text-align: center;
  padding: 5px;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 6px;
  svg {
    transform: ${({ moreDetails }) => (moreDetails ? "scale(-1)" : "scale(1)")};
  }
`;
export const ConnectionMoreDetails = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  background: #f5f5f5;
  padding: 15px 10px;
`;
export const Connectioninfo = styled.li`
  font-size: 0.8rem;
  margin: 8px;
  list-style: none;
  &:nth-child(even) {
    text-align: left;
  }
`;
export const ConnectionOnline = styled.p`
  display: inline-block;
  background-color: #01e51c1d;
  color: #00a92a;
  padding: 2px 9px;
  border-radius: 8px;
`;
export const ConnectionOffline = styled.p`
  display: inline-block;
  background-color: #e501012c;
  color: #e50101;
  padding: 2px 9px;
  border-radius: 8px;
`;
export const ConnectionShowMoreToggle = styled.span`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent !important;
  cursor: pointer;
  z-index: 3;
`;
