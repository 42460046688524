import React, { useRef, useState } from "react";
import otpImg from "../../img/login1.svg";
import axios from "axios";
import { Button } from "../ButtonElements";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import {
  LoginUsernameContainer,
  LoginUsernameFrom,
  LoginUsernameImg,
  LoginUsernameInput,
  LoginUsernameInputContainer,
  LoginUsernameTitle,
  LoginUsernameAlert,
  LoadingIcon,
} from "./LoginUsernameElements";

const cookies = new Cookies();
const LoginUsername = ({ handelTokenValidation }) => {
  const navigate = useNavigate();
  const usernameInput = useRef(null);
  const passwordInput = useRef(null);
  const [state, setState] = useState({
    errorText: "",
    loading: false,
  });

  const handelAuthentication = (e) => {
    e.preventDefault();
    setState({ ...state, loading: true });
    const form = new FormData();
    form.append("username", usernameInput.current.value);
    form.append("password", passwordInput.current.value);
    form.append("device", "Website");

    const options = {
      method: "POST",
      url: "https://jetup.app/api/auth",
      headers: {
        Accept: "application/json",
        "Content-Type":
          "multipart/form-data; boundary=---011000010111000001101001",
      },
      data: form,
    };

    axios
      .request(options)
      .then((response) => {
        setState({ ...state, loading: false });
        if (response.status === 200) {
          setState({ ...state, errorText: "" });
          cookies.set("token", response.data.token, {
            path: "/",
            secure: true,
            maxAge: 90000000,
          });
          navigate("/dashboard");
          handelTokenValidation();
        }
      })
      .catch((error) => {
        setState({ ...state, loading: false });
        if (error.response !== undefined) {
          setState({ ...state, errorText: error.response.data.message });
        }
      });
  };

  return (
    <LoginUsernameContainer>
      <LoginUsernameImg src={otpImg} alt="login otp" />
      <LoginUsernameTitle>ورود</LoginUsernameTitle>
      {state.errorText !== "" ? (
        <LoginUsernameAlert>{state.errorText}</LoginUsernameAlert>
      ) : (
        ""
      )}

      <LoginUsernameFrom
        onSubmit={(e) => {
          handelAuthentication(e);
        }}
      >
        <LoginUsernameInputContainer>
          <LoginUsernameInput
            type="text"
            placeholder="نام کاربری"
            ref={usernameInput}
          />
        </LoginUsernameInputContainer>
        <LoginUsernameInputContainer>
          <LoginUsernameInput
            type="password"
            placeholder="رمز عبور"
            ref={passwordInput}
          />
        </LoginUsernameInputContainer>
        <Button disabled={state.loading}>
          {state.loading ? <LoadingIcon /> : "ورود"}
        </Button>
      </LoginUsernameFrom>
    </LoginUsernameContainer>
  );
};

export default LoginUsername;
