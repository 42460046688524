import styled from "styled-components";
import Wave from "react-wavify";

export const Expiration = styled.div`
  position: relative;
  background-color: #0165ff;
  box-shadow: #0165ff6e 0px 7px 29px 0px;
  padding: 20px;
  border-radius: 20px;
  margin-top: 29px;
  overflow: hidden;
`;
export const ExpirationTitle = styled.h3`
  position: relative;
  font-size: 1.2rem;
  color: #fff;
  z-index: 1;
  @media screen and (min-width: 395px) {
    font-size: 1.3rem;
  }
  svg {
    margin-left: 4px;
    vertical-align: text-top;
  }
`;
export const ExpirationContent = styled.div`
  position: relative;
  margin-top: 12px;
  z-index: 1;
`;
export const ExpirationTime = styled.p`
  color: #fff;
  font-size: 14px;
  @media screen and (min-width: 395px) {
    font-size: 0.95rem;
  }
`;
export const ExpirationContentWave = styled(Wave)`
  position: absolute;
  bottom: -17px;
  left: 0;
  right: 0;
`;
export const ExpirationName = styled.p`
  color: #fff;
  font-size: 14px;
  margin-top: 4px;
`;
export const ExpirationInfo = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-top: 12px;
`;
export const ExpirationInfoChild = styled.li`
  background-color: #0165ff;
  color: #fff;
  font-size: 0.95rem;
  list-style: none;
  text-align: center;
  padding: 14px 0 15px;
  border-radius: 10px;
  svg {
    font-size: 1.3rem;
    vertical-align: bottom;
    margin-left: 3px;
  }
`;
