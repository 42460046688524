import React, { useEffect } from "react";
import LoginOption from "../components/LoginOption";
import { Navigate } from "react-router-dom";
import LoadingPage from "../components/LoadingPage";
import ApiErrorPage from "../components/ApiError";

const LoginOptionPage = ({ tokenValidation, loadingPage, apiError }) => {
  useEffect(() => {
    document.title = "جت آپ";
  });
  if (loadingPage) {
    return <LoadingPage />;
  } else if (apiError) {
    return <ApiErrorPage />;
  } else {
    if (tokenValidation === true) {
      return <Navigate replace to="/dashboard" />;
    } else {
      return <LoginOption />;
    }
  }
};

export default LoginOptionPage;
