import React from "react";
import shape from "../../img/shape.svg";
import {
  LoginOptionButtonsContainer,
  LoginOptionContainer,
  LoginOptionShape,
  Button,
  JetUp,
} from "./LoginOptionElements";

const LoginOption = () => {
  return (
    <LoginOptionContainer>
      <LoginOptionShape src={shape} alt="shape" />
      <LoginOptionButtonsContainer>
        <JetUp>جت آپ</JetUp>
        <Button to="/login-username">ورود با حساب کاربری</Button>
        {/* <Button to="/login-otp" light={true ? 1 : 0} upspace={true ? 1 : 0}>
          ورود با کد OTP
        </Button> */}
      </LoginOptionButtonsContainer>
    </LoginOptionContainer>
  );
};

export default LoginOption;
