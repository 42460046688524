import React, { useEffect, useRef, useState } from "react";
import Menu from "../Menu";
import {
  LoadingIcon,
  ProfileAlert,
  Profilecontainer,
  ProfileCount,
  ProfileDetails,
  ProfileForm,
  ProfileInput,
  ProfileInputDiv,
  ProfileLabel,
  ProfileName,
  ProfilePhoto,
} from "./ProfileElements";
import { Button } from "../ButtonElements";
import axios from "axios";
import Cookies from "universal-cookie";
import { animateScroll as scroll } from "react-scroll";
import Swal from "sweetalert2";
import ContentLoader from "react-content-loader";

const cookies = new Cookies();

const Profile = ({
  profileState = null,
  dashboardState,
  SetDashboardState,
  SetConnectionState,
  handelLogout,
  tokenValidation,
  notifactionState,
  setNotifactionState,
}) => {
  const currentPasswordInput = useRef(null);
  const newPasswordInput = useRef(null);
  const newPasswordConfirmationInput = useRef(null);
  const [state, setState] = useState({
    loading: false,
    errorText: "",
  });
  const [dashNumber, setDashNumber] = useState(0);

  useEffect(() => {
    document.title = "حساب کاربری";
    handelConnections();
    handleRequest();
  });
  const handleRequest = () => {
    const options = {
      method: "GET",
      url: `https://jetup.app/api/user/profile`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${cookies.get("token")}`,
      },
    };
    axios
      .request(options)
      .then(() => {})
      .catch((error) => {
        if (error.response.status === 401) {
          handelLogOutMenu();
        }
      });
  };
  const handelLogOutMenu = () => {
    cookies.remove("token", { path: "/" });
    let IntervalChecking = setInterval(() => {
      if (typeof handelLogout === "function") {
        handelLogout();
        clearInterval(IntervalChecking);
      } else {
        console.log("handel logout is not function yet :||| ");
      }
    }, 1000);
  };
  const handelConnections = () => {
    if (
      dashboardState !== false &&
      dashNumber === 0 &&
      dashboardState !== null
    ) {
      setDashNumber(1);
      const dashboardItems = { data: [...dashboardState.data] };
      SetDashboardState({ data: [...dashboardItems.data.slice(0, 20)] });
      SetConnectionState({ data: [...dashboardItems.data.slice(0, 20)] });
    }
  };
  const handelChangePassword = (e) => {
    e.preventDefault();
    setState({ ...state, loading: true });
    const form = new FormData();
    form.append("current_password", currentPasswordInput.current.value);
    form.append("new_password", newPasswordInput.current.value);
    form.append(
      "new_password_confirmation",
      newPasswordConfirmationInput.current.value
    );

    const options = {
      method: "POST",
      url: "https://jetup.app/api/user/profile/update/password",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${cookies.get("token")}`,
      },
      data: form,
    };

    axios
      .request(options)
      .then((response) => {
        setState({ ...state, loading: false });
        console.log(response);
        if (response.status === 200) {
          setState({
            ...state,
            errorText: "",
          });
          currentPasswordInput.current.value = "";
          newPasswordInput.current.value = "";
          newPasswordConfirmationInput.current.value = "";
          Swal.fire({
            html: "رمز عبور شما با موفقیت تغییر کرد.",
            icon: "success",
            confirmButtonText: "بستن",
          });
        }
      })
      .catch((error) => {
        setState({ ...state, loading: false });
        scroll.scrollToTop();
        setState({ ...state, errorText: error.response.data.message });
      });
  };
  return (
    <>
      <Menu
        blueBack
        logout
        workLogOut={profileState === null ? false : true}
        notifactionState={notifactionState}
        setNotifactionState={setNotifactionState}
        handelLogout={handelLogout}
        tokenValidation={tokenValidation}
      />
      <Profilecontainer>
        <ProfileDetails>
          <ProfilePhoto>
            {profileState === null ? "" : profileState.full_name.slice(0, 1)}
          </ProfilePhoto>
          <ProfileName>
            {profileState === null ? (
              <ContentLoader
                width="65px"
                height="20px"
                viewBox="0 0 200 60"
                backgroundColor="#b1b1b1d2"
                foregroundColor="#ecebebc7"
                style={{
                  position: "relative",
                  zIndex: 5,
                  verticalAlign: "top",
                  display: "inline",
                }}
              >
                <rect x="0" y="20" rx="0" ry="0" width="340" height="33" />
              </ContentLoader>
            ) : (
              profileState.full_name
            )}
          </ProfileName>
          <ProfileCount>
            تعداد کانکشن ها :{" "}
            {profileState === null ? (
              <ContentLoader
                width="65px"
                height="20px"
                viewBox="0 0 200 60"
                backgroundColor="#b1b1b1d2"
                foregroundColor="#ecebebc7"
                style={{
                  position: "relative",
                  zIndex: 5,
                  verticalAlign: "top",
                  display: "inline",
                }}
              >
                <rect x="100" y="20" rx="0" ry="0" width="80" height="33" />
              </ContentLoader>
            ) : (
              profileState.connections_count
            )}
          </ProfileCount>
        </ProfileDetails>
        <ProfileForm
          onSubmit={(e) => {
            handelChangePassword(e);
          }}
        >
          {state.errorText !== "" ? (
            <ProfileAlert>{state.errorText}</ProfileAlert>
          ) : (
            ""
          )}
          <ProfileInputDiv>
            <ProfileLabel>ایمیل</ProfileLabel>
            <ProfileInput
              type="text"
              value={
                profileState === null
                  ? ""
                  : profileState.email === null
                  ? ""
                  : profileState.email
              }
              disabled
            />
          </ProfileInputDiv>
          <ProfileInputDiv>
            <ProfileLabel>شماره موبایل</ProfileLabel>
            <ProfileInput
              type="text"
              value={
                profileState === null
                  ? ""
                  : profileState.number === null
                  ? ""
                  : profileState.number
              }
              disabled
            />
          </ProfileInputDiv>
          <ProfileInputDiv>
            <ProfileLabel>رمز عبور فعلی</ProfileLabel>
            <ProfileInput
              type="password"
              name="currentPassword"
              ref={currentPasswordInput}
            />
          </ProfileInputDiv>
          <ProfileInputDiv>
            <ProfileLabel>رمز عبور جدید</ProfileLabel>
            <ProfileInput
              type="password"
              name="newPassword"
              ref={newPasswordInput}
            />
          </ProfileInputDiv>
          <ProfileInputDiv>
            <ProfileLabel>تکرار رمز عبور جدید</ProfileLabel>
            <ProfileInput
              type="password"
              name="newPasswordConfirmation"
              ref={newPasswordConfirmationInput}
            />
          </ProfileInputDiv>
          <Button disabled={state.loading}>
            {state.loading ? <LoadingIcon /> : "ارسال"}
          </Button>
        </ProfileForm>
      </Profilecontainer>
    </>
  );
};

export default Profile;
