import styled from "styled-components";

export const Productcontainer = styled.div`
  display: block;
  background-color: #f7f7f7;
  min-height: 100vh;
  padding: 0 0 115px;
`;
export const ProductTabs = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  flex-wrap: wrap;
`;
export const ProductTab = styled.li`
  display: block;
  width: calc(50% - 10px);
  font-size: 0.9rem;
  background-color: #eaeaea;
  text-align: center;
  padding: 14px;
  border-radius: 10px;
  margin: 20px auto 0;
  list-style: none;
  cursor: pointer;
  &.active {
    color: #fff;
    background-color: #0165ff;
  }
`;
export const ProductItems = styled.div`
  margin-top: 20px;
  padding: 0 24px;
`;
