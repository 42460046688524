import styled from "styled-components";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

export const Profilecontainer = styled.div`
  display: block;
  background-color: #f7f7f7;
  min-height: 100vh;
`;
export const ProfileDetails = styled.div`
  background-color: #0165ff;
  height: 100px;
  padding: 47px 24px 0;
`;
export const ProfilePhoto = styled.div`
  width: 100px;
  height: 100px;
  background-color: #a1a1a1;
  border-radius: 50%;
  border: 6px solid #f7f7f7;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-flow: column;
  text-align: center;
  color: #fff;
  padding-top: 9px;
  font-size: 2.3rem;
`;
export const ProfileName = styled.h3`
  text-align: center;
  margin-top: 7px;
  @media screen and (min-width: 395px) {
    font-size: 1.3rem;
  }
`;
export const ProfileCount = styled.p`
  font-size: 0.875rem;
  text-align: center;
  margin-top: 7px;
  @media screen and (min-width: 395px) {
    font-size: 0.95rem;
  }
`;
export const ProfileForm = styled.form`
  margin-top: 120px;
  padding: 0 24px 120px;
`;
export const ProfileInputDiv = styled.div`
  margin-bottom: 25px;
`;
export const ProfileLabel = styled.label`
  display: inline-block;
  font-size: 14px;
  margin-bottom: 10px;
  @media screen and (min-width: 395px) {
    font-size: 0.95rem;
  }
`;
export const ProfileInput = styled.input`
  display: block;
  width: 100%;
  padding: 13px;
  background-color: #fff;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  &:focus {
    border: 1px solid #969696;
  }
  @media screen and (min-width: 395px) {
    font-size: 0.95rem;
  }
  &:disabled {
    background-color: #e6e6e6;
  }
`;
export const LoadingIcon = styled(AiOutlineLoading3Quarters)`
  width: 20px;
  height: auto;
  -webkit-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
`;
export const ProfileAlert = styled.p`
  padding: 10px;
  margin: 10px 0;
  font-size: 14px;
  border-right: 4px solid #eb2f2f;
  background-color: #ee707021;
  color: #eb2f2f;
`;
