import styled from "styled-components";

export const ApiErrorContainer = styled.section`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
`;
export const ApiErrorText = styled.p`
  font-size: 1.3rem;
  text-align: center;
  color: #0165ff;
  padding: 10px;
`;
