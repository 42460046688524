import styled from "styled-components";

export const Button = styled.button`
  font-family: "yekan";
  height: 46px;
  display: inline-block;
  width: 100%;
  padding: 12px 15px;
  background-color: ${({ light }) => (light ? "#fff" : "#0165ff")};
  color: ${({ light }) => (light ? "#0165ff" : "#fff")};
  border: 1px solid #0165ff;
  margin-top: ${({ upspace }) => (upspace ? "1rem" : "0")};
  text-align: center;
  border-radius: 8px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease-out;
  @media screen and (min-width: 395px) {
    height: 50px;
    font-size: 0.95rem;
  }
  &:disabled {
    background-color: ${({ light }) => (light ? "#fff" : "#2e75df")};
    border: 1px solid #2e75df;
    cursor: no-drop;
    &:hover {
      background-color: ${({ light }) =>
        light ? "#fff" : "#2e75df"} !important;
      color: #fff;
    }
  }
  &:hover {
    background-color: ${({ light }) => (light ? "#0165ff" : "#0041a2")};
    color: #fff;
  }
`;
