import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const NavbarContainer = styled.div`
  position: fixed;
  width: 95%;
  left: 0;
  right: 0;
  bottom: 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #fff;
  margin: 0 auto;
  flex-wrap: wrap;
  border-radius: 20px;
  z-index: 999;
  box-shadow: 0 0 16px -7px #0000006b;
  overflow: hidden;
`;
export const NavItem = styled(NavLink)`
  display: block;
  height: 100%;
  position: relative;
  width: calc(100% / 4);
  font-size: 13px;
  position: relative;
  padding: 15px 5px;
  color: #717171;
  text-align: center;
  @media screen and (min-width: 395px) {
    font-size: 14px;
  }
  svg {
    display: block;
    width: 23px;
    height: 23px;
    object-fit: contain;
    text-align: center;
    margin: 0 auto 10px;
  }
  &.active {
    color: #0165ff;
    &::after {
      content: "";
      position: absolute;
      width: 60%;
      height: 4px;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0 auto;
      border-radius: 3px 3px 0 0;
      background-color: #0165ff;
    }
  }
`;
