import styled from "styled-components";

export const ProductItemContainer = styled.article`
  display: block;
  box-shadow: #acacac3f 0px 7px 29px 0px;
  border-radius: 20px;
  overflow: hidden;
  margin-top: 30px;
`;
export const ProductItemContent = styled.div`
  position: relative;
  background-color: ${({ bold }) => (bold ? "#0165ff" : "#ffffff")};
  padding: 20px;
  border-radius: 20px 20px 0 0;
`;
export const ProductItemFlag = styled.div`
  position: relative;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  background-color: ${({ bold }) => (bold ? "#408bfd" : "#f3f2f2")};
  margin: 0 auto 20px;
  border-radius: 50%;
  z-index: 1;
  span {
    filter: drop-shadow(
      0px 2px 5px ${({ bold }) => (bold ? "#ffffffc0" : "#787171b5")}
    );
  }
`;
export const ProductItemTitle = styled.h1`
  color: ${({ bold }) => (bold ? "#fff" : "#0165ff")};
`;
export const ProductItemDescription = styled.p`
  font-size: 0.95rem;
  color: ${({ bold }) => (bold ? "#fffffff0" : "#212121")};
  margin-top: 10px;
  white-space: pre-wrap;
`;
export const ProductItemDetails = styled.ul`
  display: block;
`;
export const ProductItemDetail = styled.li`
  color: ${({ bold }) => (bold ? "#ffffffd9" : "#212121")};
  font-size: 0.9rem;
  margin-top: 15px;
  list-style: none;
  svg {
    color: ${({ bold }) => (bold ? "#ffffffd9" : "#212121")};
    font-size: 0.7rem;
    vertical-align: middle;
    margin-left: 5px;
  }
`;
export const ProductItemPrice = styled.h4`
  position: relative;
  font-size: 1.4rem;
  background-color: ${({ bold }) => (bold ? "#408bfd" : "#f3f2f2")};
  padding: 25px 10px;
  color: ${({ bold }) => (bold ? "#fff" : "#212121")};
  border-radius: 0 0 20px 20px;
  text-align: center;
  z-index: 1;
`;
export const ProductItemShape = styled.ul`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.06);
    animation: animate 25s linear infinite;
    bottom: -150px;

    &:nth-child(1) {
      left: 25%;
      width: 80px;
      height: 80px;
      animation-delay: 0s;
    }
    &:nth-child(2) {
      left: 10%;
      width: 20px;
      height: 20px;
      animation-delay: 3s;
      animation-duration: 12s;
    }
    &:nth-child(3) {
      left: 70%;
      width: 20px;
      height: 20px;
      animation-delay: 5s;
    }
    &:nth-child(4) {
      left: 40%;
      width: 60px;
      height: 60px;
      animation-delay: 0s;
      animation-duration: 18s;
    }
    &:nth-child(5) {
      left: 65%;
      width: 20px;
      height: 20px;
      animation-delay: 0s;
    }
    &:nth-child(6) {
      left: 75%;
      width: 110px;
      height: 110px;
      animation-delay: 3s;
    }
    &:nth-child(7) {
      left: 35%;
      width: 150px;
      height: 150px;
      animation-delay: 7s;
    }
    &:nth-child(8) {
      left: 50%;
      width: 25px;
      height: 25px;
      animation-delay: 15s;
      animation-duration: 45s;
    }
    &:nth-child(9) {
      left: 20%;
      width: 15px;
      height: 15px;
      animation-delay: 2s;
      animation-duration: 35s;
    }
    &:nth-child(10) {
      left: 85%;
      width: 150px;
      height: 150px;
      animation-delay: 0s;
      animation-duration: 11s;
    }
  }
`;
