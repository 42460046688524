import styled from "styled-components";
import { Link } from "react-router-dom";

export const NotFoundContainer = styled.section`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  background-color: #fff;
  overflow: hidden;
`;

export const NotFoundShape = styled.img`
  position: absolute;
  right: -32px;
  top: -100px;
  width: 273px;
  z-index: 1;
`;

export const NotFoundTitle = styled.h4`
  display: block;
  font-size: 4rem;
  color: #0165ff;
  text-align: center;
  margin-bottom: 10px;
`;

export const NotFoundButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
`;

export const Button = styled(Link)`
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 170px;
  padding: 13px 15px;
  background-color: ${({ light }) => (light ? "#fff" : "#0165ff")};
  color: ${({ light }) => (light ? "#0165ff" : "#fff")};
  border: 1px solid #0165ff;
  margin-top: ${({ upspace }) => (upspace ? "1rem" : "0")};
  text-align: center;
  border-radius: 8px;
  text-decoration: none;
  cursor: pointer;
  z-index: 2;
  transition: all 0.3s ease-out;
  @media screen and (max-height: 390px) {
    background-color: ${({ light }) => (light ? "#fff" : "#0041a2")};
  }
  &:hover {
    background-color: ${({ light }) => (light ? "#0165ff" : "#0165ff")};
    color: #fff;
  }
`;
