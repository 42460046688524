import styled from "styled-components";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

export const HomeContainer = styled.main`
  display: block;
  background-color: #f7f7f7;
  min-height: 100vh;
`;
export const HomeExpirationContainer = styled.section`
  padding: 0 24px;
`;
export const HomeSpeedTestContainer = styled.section`
  padding: 0 24px;
  margin-top: 50px;
`;
export const HomeConnectionsContainer = styled.section`
  margin-top: 50px;
  padding: 0 24px 115px;
`;
export const HomeConnectionsTitle = styled.h3`
  font-size: 1.3rem;
  @media screen and (min-width: 395px) {
    font-size: 1.4rem;
  }
`;
export const HomeConnectionsSearch = styled.input`
  width: 100%;
  font-family: "yekan";
  border: 1px solid #e2e2e2;
  background-color: #fff;
  border-radius: 12px;
  margin-top: 20px;
  padding: 15px;
  @media screen and (min-width: 395px) {
    font-size: 0.95rem;
  }
`;
export const HomeError = styled.h3`
  font-size: 1.3rem;
  text-align: center;
  color: #919191;
  padding: 90px 24px 0;
  line-height: 2rem;
`;
export const LoadingIcon = styled(AiOutlineLoading3Quarters)`
  font-size: 2rem;
  color: #0165ff;
  -webkit-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
`;
