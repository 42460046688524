import styled from "styled-components";

export const NewsContainer = styled.main`
  display: block;
  background-color: #f7f7f7;
  min-height: 100vh;
  padding: 20px 24px 115px;
`;
export const NewsItem = styled.article`
  background-color: #fff;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 15px;
`;
export const NewsError = styled.h3`
  font-size: 1.3rem;
  text-align: center;
  color: #919191;
  margin-top: 90px;
  line-height: 2rem;
`;
