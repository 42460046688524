import React from "react";
import { Outlet } from "react-router-dom";
import LoadingPage from "../components/LoadingPage";
import Navbar from "../components/Navbar";
import { Navigate } from "react-router-dom";
import ApiErrorPage from "../components/ApiError";

const Dashboard = ({ tokenValidation, loadingPage, apiError }) => {
  if (loadingPage) {
    return <LoadingPage />;
  } else if (apiError) {
    return <ApiErrorPage />;
  } else {
    if (tokenValidation === false) {
      return <Navigate replace to="/" />;
    } else {
      return (
        <>
          <Outlet tokenValidation={tokenValidation} />
          <Navbar />
        </>
      );
    }
  }
};

export default Dashboard;
