import React, { useState } from "react";
import ReactCountryFlag from "react-country-flag";
import {
  ConnectionErrorText,
  ConnectionShow,
  ConnectionName,
  ConnectionsContainer,
  ConnectionShowMoreDetails,
  ConnectionCopyText,
  ConnectionMoreDetails,
  Connectioninfo,
  ConnectionOffline,
  ConnectionOnline,
  ConnectionCopyQrCode,
  ConnectionShowMoreToggle,
  ConnectionShare,
} from "./ConnectionsElements";
import { IoIosArrowDown } from "react-icons/io";
import { IoCopy } from "react-icons/io5";
import { BsCheckLg } from "react-icons/bs";
import { BiShareAlt } from "react-icons/bi";
import { HiOutlineQrcode } from "react-icons/hi";

const Connections = ({
  name = null,
  country = null,
  status = null,
  upload = null,
  download = null,
  expire = null,
  vpnText = null,
  handelQrcode,
  expired,
  infinity,
  reseller,
  shreUrl = null,
}) => {
  const [copy, setCopy] = useState(false);
  const [moreDetails, setMoreDetails] = useState(false);
  const handelClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };
  const handelMoreDetails = () => {
    setMoreDetails(!moreDetails);
  };
  return (
    <ConnectionsContainer
      moreDetails={moreDetails}
      expired={expired}
      infinity={infinity}
    >
      <ConnectionShow>
        <ConnectionShowMoreToggle
          onClick={handelMoreDetails}
          moreDetails={moreDetails}
        />
        <ReactCountryFlag
          className="emojiFlag"
          countryCode={country}
          style={{
            fontSize: "2em",
            lineHeight: "1em",
            position: "relative",
            top: "6px",
          }}
        />
        <ConnectionName title={name}>{name}</ConnectionName>
        {vpnText === null ? (
          <ConnectionErrorText>منتظر بمانید</ConnectionErrorText>
        ) : reseller ? (
          <ConnectionShare
            type="button"
            onClick={() => {
              handelClipboard(shreUrl);
            }}
          >
            {copy ? <BsCheckLg style={{ color: "#34cf63" }} /> : <BiShareAlt />}
          </ConnectionShare>
        ) : (
          <ConnectionCopyText
            type="button"
            onClick={() => {
              handelClipboard(vpnText);
            }}
          >
            {copy ? <BsCheckLg style={{ color: "#34cf63" }} /> : <IoCopy />}
            {copy ? "کپی شد" : "کپی"}
          </ConnectionCopyText>
        )}
        {vpnText === null ? (
          ""
        ) : (
          <ConnectionCopyQrCode
            onClick={() => {
              handelQrcode(vpnText);
            }}
          >
            <HiOutlineQrcode />
          </ConnectionCopyQrCode>
        )}
        <ConnectionShowMoreDetails
          onClick={handelMoreDetails}
          moreDetails={moreDetails}
        >
          <IoIosArrowDown />
        </ConnectionShowMoreDetails>
      </ConnectionShow>
      <ConnectionMoreDetails>
        <Connectioninfo>وضعیت سرور شما :</Connectioninfo>
        <Connectioninfo>
          {status === "online" ? (
            <ConnectionOnline>آنلاین</ConnectionOnline>
          ) : (
            <ConnectionOffline>آفلاین</ConnectionOffline>
          )}
        </Connectioninfo>
        <Connectioninfo>مهلت سرویس :</Connectioninfo>
        <Connectioninfo>{expire}</Connectioninfo>
        <Connectioninfo>دانلود :</Connectioninfo>
        <Connectioninfo>{download}</Connectioninfo>
        <Connectioninfo>آپلود :</Connectioninfo>
        <Connectioninfo>{upload}</Connectioninfo>
      </ConnectionMoreDetails>
    </ConnectionsContainer>
  );
};

export default Connections;
