import React, { useEffect, useState } from "react";
import {
  MenuHeader,
  MenuHeaderLogo,
  MenuHeaderTelegram,
  MenuHeaderNotifaction,
  MenuNotifactionContainer,
  MenuNotifaction,
  MenuNotifactionTitle,
  MenuNotifactionDescription,
  MenuNotifactionOutSide,
  MenuLogOut,
  LoadingIcon,
  MenuNotifactionButton,
  Notif,
  LoadingNotif,
  MenuHeaderInfo,
  AppInfoLogo,
  AppInfoTitle,
  AppInfoVersion,
} from "./MenuElements";
import logo from "../../img/jetup.svg";
import { FaTelegramPlane } from "react-icons/fa";
import { BsInfoLg } from "react-icons/bs";
import { IoIosNotifications } from "react-icons/io";
import axios from "axios";
import { cookies } from "../../App";
import ContentLoader from "react-content-loader";
import Modal from "../Modal";

const Menu = ({
  blueBack = false,
  logout = false,
  workLogOut = false,
  handelLogout,
  notifactionCount,
  tokenValidation,
  setNotifactionCount,
  notifactionState,
  setNotifactionState,
}) => {
  const [state, setState] = useState(notifactionState);
  const [notifaction, setNotifaction] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);

  useEffect(() => {
    if (notifactionState === null && logout === false) {
      handleRequest();
    }
  });
  const handleRequest = (e = null) => {
    const options = {
      method: "GET",
      url: "https://jetup.app/api/user/notifications",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${cookies.get("token")}`,
      },
    };
    axios
      .request(options)
      .then((response) => {
        if (response.status === 200) {
          setState({ data: [...response.data.data] });
          setNotifactionState({ data: [...response.data.data] });
          if (logout === false) {
            handelNotifactionNumber(response.data.data);
          }
          if (e !== null) {
            e.target.style.background = "transparent";
          }
        }
      })
      .catch((error) => {
        if (error.response !== undefined) {
          console.log(error.response);
          if (error.response.status === 401) {
            setNotifactionState({ data: [] });
            handelLogOutMenu();
          }
        } else {
          console.log(error);
          if (error.status === 401) {
            setNotifactionState({ data: [] });
            handelLogOutMenu();
          }
        }
      });
  };
  const handelNotifaction = () => {
    setNotifaction(!notifaction);
  };
  const handelLogOutMenu = () => {
    setLoading(true);
    cookies.remove("token", { path: "/" });
    setTimeout(() => {
      if (typeof handelLogout === "function") {
        handelLogout();
      } else {
        console.log("handel logout is not function yet :||| ");
      }
      setLoading(false);
    }, 1000);
  };
  const handelNotifactionNumber = (data) => {
    const notif = data.findIndex((user) => user.seen === 0);
    if (tokenValidation !== false) {
      if (notif === -1) {
        setNotifactionCount(false);
      } else {
        setNotifactionCount(true);
      }
    }
  };
  const handelSeenNotifaction = (e) => {
    if (e.target.getAttribute("data-seen") === "false") {
      const notifId = e.target.getAttribute("data-notif-id");
      e.target.style.background = "#ffffff61";
      const options = {
        method: "POST",
        url: `https://jetup.app/api/user/notifications/seen/${notifId}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${cookies.get("token")}`,
        },
      };
      axios
        .request(options)
        .then((response) => {
          if (response.status === 200) {
            handleRequest(e);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const handelInfoModal = () => {
    setToggleModal(!toggleModal);
  };
  return (
    <MenuHeader blueBack={blueBack}>
      <MenuHeaderLogo to="/dashboard">جت آپ</MenuHeaderLogo>
      <MenuHeaderTelegram href="tg://resolve?domain=jetup_app">
        <FaTelegramPlane />
      </MenuHeaderTelegram>
      {logout === false ? (
        <MenuHeaderNotifaction type="button" onClick={handelNotifaction}>
          <IoIosNotifications />
          {notifactionCount ? <Notif /> : ""}
        </MenuHeaderNotifaction>
      ) : (
        ""
      )}
      {notifaction ? (
        <MenuNotifactionContainer>
          {state !== null ? (
            state.data.map((e, i) => (
              <MenuNotifaction
                key={i}
                seen={e.seen === 0 ? false : true}
                onClick={(el) => {
                  handelSeenNotifaction(el);
                }}
              >
                <MenuNotifactionButton
                  data-notif-id={e.id}
                  data-seen={e.seen === 0 ? false : true}
                ></MenuNotifactionButton>
                <MenuNotifactionTitle seen={e.seen === 0 ? false : true}>
                  {e.title}
                </MenuNotifactionTitle>
                <MenuNotifactionDescription>
                  {e.message}
                </MenuNotifactionDescription>
              </MenuNotifaction>
            ))
          ) : (
            <li>
              <LoadingNotif />
            </li>
          )}
        </MenuNotifactionContainer>
      ) : (
        ""
      )}
      {state !== null && notifaction ? (
        <MenuNotifactionOutSide onClick={handelNotifaction} />
      ) : (
        ""
      )}
      {logout ? (
        <>
          <MenuHeaderInfo onClick={handelInfoModal}>
            <BsInfoLg />
          </MenuHeaderInfo>
          {toggleModal === true ? (
            <Modal handelModalToggle={handelInfoModal} title="">
              <AppInfoLogo src={logo} />
              <AppInfoTitle>جت آپ</AppInfoTitle>
              <AppInfoVersion>1.2v</AppInfoVersion>
            </Modal>
          ) : (
            ""
          )}
          {workLogOut === false ? (
            <ContentLoader
              width="80px"
              height="40px"
              viewBox="0 0 230 120"
              backgroundColor="#ffffff4e"
              foregroundColor="#ecebebc7"
              style={{
                position: "relative",
                zIndex: 5,
                verticalAlign: "top",
                display: "inline",
                marginRight: 10,
              }}
            >
              <rect x="0" y="0" rx="20" ry="20" width="100%" height="100%" />
            </ContentLoader>
          ) : (
            <MenuLogOut type="button" onClick={handelLogOutMenu}>
              {loading ? <LoadingIcon /> : "خروج"}
            </MenuLogOut>
          )}
        </>
      ) : (
        ""
      )}
    </MenuHeader>
  );
};

export default Menu;
