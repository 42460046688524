import React from "react";
import shape from "../../img/shape.svg";
import {
  NotFoundButtonsContainer,
  NotFoundContainer,
  NotFoundShape,
  Button,
  NotFoundTitle,
} from "./NotFoundElements";

const NotFound = () => {
  return (
    <NotFoundContainer>
      <NotFoundShape src={shape} alt="shape" />
      <NotFoundButtonsContainer>
        <NotFoundTitle>404</NotFoundTitle>
        <Button to="/">بازگشت به خانه</Button>
      </NotFoundButtonsContainer>
    </NotFoundContainer>
  );
};

export default NotFound;
