import React, { useEffect } from "react";
import LoginUsername from "../components/LoginUsername";
import { Navigate } from "react-router-dom";
import LoadingPage from "../components/LoadingPage";
import ApiErrorPage from "../components/ApiError";

const LoginUsernamePage = ({
  tokenValidation,
  loadingPage,
  handelTokenValidation,
  apiError,
}) => {
  useEffect(() => {
    document.title = "ورود با حساب کاربری";
  });

  if (loadingPage) {
    return <LoadingPage />;
  } else if (apiError) {
    return <ApiErrorPage />;
  } else {
    if (tokenValidation === true) {
      return <Navigate replace to="/dashboard" />;
    } else {
      return (
        <LoginUsername
          handelTokenValidation={() => {
            handelTokenValidation();
          }}
        />
      );
    }
  }
};

export default LoginUsernamePage;
