import styled from "styled-components";
import { Link } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

export const MenuHeader = styled.header`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: ${({ blueBack }) => (blueBack ? "#0165ff" : "#f7f7f7")};
  padding: 12px 24px;
  svg {
    display: block;
  }
`;
export const MenuHeaderLogo = styled(Link)`
  display: inline-flex;
  justify-content: center;
  align-content: center;
  flex-flow: column wrap;
  font-family: "yekan-bold";
  color: #2e2e2e;
  height: 40px;
  background-color: #ececec;
  font-size: 1.2rem;
  padding: 10px;
  border-radius: 8px;
`;

export const MenuHeaderNotifaction = styled.button`
  position: relative;
  display: inline-flex;
  justify-content: center;
  flex-flow: column wrap;
  align-content: center;
  height: 40px;
  width: 40px;
  color: #2e2e2e;
  background-color: #ececec;
  font-size: 1.4rem;
  text-align: center;
  padding: 8px;
  border-radius: 8px;
  margin-right: 10px;
  cursor: pointer;
  z-index: 9;
`;

export const MenuHeaderTelegram = styled.a`
  display: inline-flex;
  justify-content: center;
  flex-flow: column wrap;
  align-content: center;
  height: 40px;
  width: 40px;
  color: #2e2e2e;
  background-color: #ececec;
  font-size: 1.3rem;
  text-align: center;
  padding: 8px;
  border-radius: 8px;
  margin-right: auto;
`;
export const MenuNotifactionContainer = styled.ul`
  position: absolute;
  top: calc(100% + -4px);
  left: 24px;
  width: 250px;
  height: 270px;
  background-color: #fff;
  scrollbar-color: #bbbcc1 #e4e4e4;
  scrollbar-width: thin;
  border-radius: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  z-index: 1001;
  overflow: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #e4e4e4;
  }

  &::-webkit-scrollbar-thumb {
    background: #bbbcc1;
    border-radius: 0;
    border: 1px solid #e4e4e4;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #929398;
  }
`;
export const MenuNotifaction = styled.li`
  position: relative;
  background-color: ${({ seen }) => (seen ? "#fff" : "#f2f2f2b9")};
  border-bottom: 1px solid #d6d5d5;
  padding: 15px;
  list-style: none;
  cursor: pointer;
  &:last-child {
    border-bottom: none;
  }
`;
export const MenuNotifactionTitle = styled.h3`
  font-size: 1rem;
  color: ${({ seen }) => (seen ? "#000" : "#015ae5")};
`;
export const MenuNotifactionButton = styled.h3`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
`;
export const MenuNotifactionDescription = styled.p`
  font-size: 0.8rem;
  color: #353535;
  margin-top: 6px;
`;
export const MenuNotifactionOutSide = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 1000;
`;
export const MenuLogOut = styled.button`
  display: inline-flex;
  justify-content: center;
  flex-flow: column wrap;
  align-content: center;
  height: 40px;
  width: 80px;
  background: #ef4141;
  color: #fff;
  padding: 5px;
  border-radius: 8px;
  margin-right: 10px;
  transition: all 0.3s ease-out;
  cursor: pointer;
  svg {
    display: block;
  }
  &:hover {
    background: #c03333;
  }
`;
export const LoadingIcon = styled(AiOutlineLoading3Quarters)`
  position: relative;
  top: -1px;
  width: 20px;
  height: auto;
  -webkit-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
`;
export const Notif = styled.span`
  position: absolute;
  width: 6px;
  height: 6px;
  top: 13px;
  right: 12px;
  z-index: 1;
  background-color: #fc4141;
  animation: blink 1.5s linear infinite;
  border-radius: 50%;
`;
export const LoadingNotif = styled(AiOutlineLoading3Quarters)`
  font-size: 2rem;
  margin: 7rem auto 0;
  color: #0165ff;
  -webkit-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
`;
export const MenuHeaderInfo = styled.button`
  position: relative;
  display: inline-flex;
  justify-content: center;
  flex-flow: column wrap;
  align-content: center;
  height: 40px;
  width: 40px;
  color: #2e2e2e;
  background-color: #ececec;
  font-size: 1.4rem;
  text-align: center;
  padding: 8px;
  border-radius: 8px;
  margin-right: 10px;
  cursor: pointer;
  z-index: 9;
`;
export const AppInfoLogo = styled.img`
  display: block;
  width: 110px;
  max-width: 90%;
  margin: 25px auto 0;
`;
export const AppInfoTitle = styled.h3`
  font-size: 2.1rem;
  margin-top: 25px;
  color: #0165ff;
  text-align: center;
`;
export const AppInfoVersion = styled.h6`
  font-size: 1.3rem;
  margin-top: 50px;
  text-align: center;
`;
