import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import PullToRefresh from "react-simple-pull-to-refresh";
import {
  HomeConnectionsContainer,
  HomeConnectionsTitle,
  HomeContainer,
  HomeExpirationContainer,
  HomeConnectionsSearch,
  HomeError,
  HomeSpeedTestContainer,
  LoadingIcon,
} from "./HomeElements";
import Menu from "../Menu";
import Connections from "../Connections";
import ExpirationComponents from "../ExpirationComponents";
import QRCode from "react-qr-code";
import Modal from "../Modal";
import ContentLoader from "react-content-loader";
import SpeedTest from "../SpeedTest";
import InfiniteScroll from "react-infinite-scroller";
import { Button } from "../ButtonElements";

const cookies = new Cookies();

const Home = ({
  dashboardState,
  SetDashboardState,
  connectionState,
  SetConnectionState,
  lastPage,
  setLastPage,
  notifactionCount,
  setNotifactionCount,
  userInvoice,
  setUserInvoice,
  tokenValidation,
  notifactionState,
  setNotifactionState,
  handelLogout,
  reseller = false,
}) => {
  const [state, setState] = useState(dashboardState);
  const [connections, setConnections] = useState(connectionState);
  const [toggleModal, setToggleModal] = useState(false);
  const [qrcode, setQrcode] = useState("");
  const [errorText, setErrorText] = useState("");
  const [moreItem, setMoreItem] = useState(true);
  const [count, setCount] = useState(0);
  const [configText, setConfigText] = useState(null);
  const [copy, setCopy] = useState(false);

  useEffect(() => {
    document.title = "داشبورد";
    if (state === false) {
      handleRequest();
    }
  });
  const handleRequest = () => {
    setState(false);
    setConnections(false);
    setMoreItem(true);
    const options = {
      method: "GET",
      url: "https://jetup.app/api/user/connections",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${cookies.get("token")}`,
      },
    };
    axios
      .request(options)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data.data);
          if (response.data.data.length === 0) {
            setErrorText("در حال حاظر کانکشنی برای شما وجود ندارد :(");
            setState(null);
            setConnections(null);
            SetDashboardState(null);
            SetConnectionState(null);
          } else {
            setState({ data: [...response.data.data] });
            setConnections({ data: [...response.data.data] });
            SetDashboardState({ data: [...response.data.data] });
            SetConnectionState({ data: [...response.data.data] });
            setLastPage(Number(response.data.last_page));
            handelInfinityUser(response.data.data);
          }
        }
      })
      .catch((error) => {
        if (error.response !== undefined) {
          console.log(error.response);
          if (error.response.status === 500) {
            setState(null);
            SetDashboardState(false);
            setErrorText(
              "مشکلی در دریافت اطلاعات پیش آمده ، دقایقی دیگر مجددا تلاش کنید."
            );
          }
          if (error.response.status === 401) {
            handelLogOutMenu();
          }
        } else {
          console.log(error);
        }
        if (error.message === "Network Error") {
          setState(null);
          SetDashboardState(false);
          setErrorText("لطفا از اتصال اینترنت خود مطمئن شوید.");
        }
      });
  };
  const handelLogOutMenu = () => {
    cookies.remove("token", { path: "/" });
    let IntervalChecking = setInterval(() => {
      if (typeof handelLogout === "function") {
        handelLogout();
        clearInterval(IntervalChecking);
      } else {
        console.log("handel logout is not function yet :||| ");
      }
    }, 1000);
  };
  const handelSearch = (e) => {
    setMoreItem(false);
    if (e.target.value.length > 0) {
      const newConnection = state.data.filter((obj) => {
        return obj.username
          .toLowerCase()
          .includes(`${e.target.value.toLowerCase()}`);
      });
      setConnections({ data: [...newConnection] });
    } else {
      setMoreItem(true);
      setConnections({ data: [...state.data] });
    }
  };
  const handelQrcode = (text = null) => {
    setQrcode(text);
    setConfigText(text);
    setToggleModal(!toggleModal);
  };
  const handelClipboard = () => {
    navigator.clipboard.writeText(configText);
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };
  const handelLoadMore = (page) => {
    if (Number(page) - count <= lastPage) {
      const options = {
        method: "GET",
        url: `https://jetup.app/api/user/connections?page=${
          Number(page) - count
        }`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${cookies.get("token")}`,
        },
      };
      axios
        .request(options)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.data.length === 0) {
              setErrorText("در حال حاظر کانکشنی برای شما وجود ندارد :(");
              setState(null);
              setConnections(null);
              SetDashboardState(null);
              SetConnectionState(null);
            } else {
              setState({ data: [...state.data, ...response.data.data] });
              setConnections({
                data: [...connections.data, ...response.data.data],
              });
              SetDashboardState({
                data: [...dashboardState.data, ...response.data.data],
              });
              SetConnectionState({
                data: [...connectionState.data, ...response.data.data],
              });
              if (Number(page) - count === lastPage) {
                setMoreItem(false);
              }
            }
          }
        })
        .catch((error) => {
          if (error.response !== undefined) {
            console.log(error.response);
            if (error.response.status === 500) {
              setState(null);
              setErrorText(
                "مشکلی در دریافت اطلاعات پیش آمده ، دقایقی دیگر مجددا تلاش کنید."
              );
            }
            if (error.response.status === 401) {
              handelLogOutMenu();
            }
          } else {
            console.log(error);
          }
          if (error.message === "Network Error") {
            setState(null);
            setErrorText("لطفا از اتصال اینترنت خود مطمئن شوید.");
          }
        });
    } else {
      setMoreItem(false);
    }
  };
  const handelInfinityUser = (data) => {
    let userIndex = data.findIndex(
      (user) =>
        user.expiration_array !== null && user.expiration_array !== false
    );
    if (userIndex === -1) userIndex = 0;
    setUserInvoice(userIndex);
  };
  const handelBlockInfiniteScroll = () => {
    console.log("blocked !!!!!!!");
    setCount(count + 1);
  };
  return (
    <PullToRefresh
      onRefresh={() => new Promise((resolve) => resolve(handleRequest()))}
      isPullable={true}
      canFetchMore={true}
      pullingContent=""
      maxPullDownDistance={100}
      pullDownThreshold={100}
    >
      <HomeContainer>
        <Menu
          notifactionCount={notifactionCount}
          setNotifactionCount={setNotifactionCount}
          tokenValidation={tokenValidation}
          notifactionState={notifactionState}
          setNotifactionState={setNotifactionState}
        />
        {state === null ? (
          <HomeError>{errorText}</HomeError>
        ) : (
          <>
            <HomeExpirationContainer>
              <ExpirationComponents
                expire={
                  state !== false
                    ? state.data[userInvoice].expiration_array === null
                      ? "بی نهایت"
                      : state.data[userInvoice].expiration_array === false
                      ? "تمام شده"
                      : state.data[userInvoice].expiration_array.string
                    : null
                }
                accountName={
                  state !== false ? state.data[userInvoice].username : null
                }
                download={
                  state !== false
                    ? state.data[userInvoice].traffic.download
                    : null
                }
                upload={
                  state !== false
                    ? state.data[userInvoice].traffic.upload
                    : null
                }
              />
            </HomeExpirationContainer>
            <HomeSpeedTestContainer>
              <SpeedTest />
            </HomeSpeedTestContainer>
            <HomeConnectionsContainer>
              <HomeConnectionsTitle>لیست کانکشن ها</HomeConnectionsTitle>
              <HomeConnectionsSearch
                type="text"
                placeholder="جستجو ..."
                onChange={handelSearch}
              />
              {toggleModal === true ? (
                <Modal handelModalToggle={handelQrcode} title="کد QR">
                  <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={qrcode}
                    viewBox={`0 0 256 256`}
                  />
                  {reseller ? (
                    <Button onClick={handelClipboard} style={{ marginTop: 10 }}>
                      {copy ? "کپی شد" : "کپی کردن پیکربندی"}
                    </Button>
                  ) : (
                    ""
                  )}
                </Modal>
              ) : (
                ""
              )}

              {connections !== false ? (
                <InfiniteScroll
                  pageStart={1}
                  loadMore={
                    moreItem ? handelLoadMore : handelBlockInfiniteScroll
                  }
                  hasMore={moreItem}
                  loader={
                    <div
                      className="loader"
                      style={{ textAlign: "center", marginTop: "2rem" }}
                      key={0}
                    >
                      <LoadingIcon />
                    </div>
                  }
                >
                  {connections.data.map((e, i) => (
                    <Connections
                      key={i}
                      handelQrcode={handelQrcode}
                      name={e.username}
                      status={e.server_data.status}
                      upload={e.traffic.upload}
                      download={e.traffic.download}
                      expired={e.expiration_array === false ? true : false}
                      infinity={e.expiration_array === null && true}
                      reseller={reseller}
                      expire={
                        e.expiration_array === null
                          ? "بی نهایت"
                          : e.expiration_array === false
                          ? "تمام شده"
                          : e.expiration_array.string
                      }
                      vpnText={e.vmess_url}
                      shreUrl={e.share_url}
                      country={e.server_data.name.substring(0, 2)}
                    />
                  ))}
                </InfiniteScroll>
              ) : (
                <ContentLoader
                  width="100%"
                  height="100%"
                  viewBox="0 0 450 380"
                  backgroundColor="#d6d6d6bc"
                  foregroundColor="#dfdfdfaf"
                >
                  <rect x="0" y="30" rx="15" ry="15" width="100%" height="90" />

                  <rect
                    x="0"
                    y="150"
                    rx="15"
                    ry="15"
                    width="100%"
                    height="90"
                  />

                  <rect
                    x="0"
                    y="270"
                    rx="15"
                    ry="15"
                    width="100%"
                    height="90"
                  />
                </ContentLoader>
              )}
            </HomeConnectionsContainer>
          </>
        )}
      </HomeContainer>
    </PullToRefresh>
  );
};

export default Home;
