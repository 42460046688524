import React from "react";
import { FaUserCircle, FaNewspaper } from "react-icons/fa";
import { MdHomeFilled } from "react-icons/md";
import { BsFillBasketFill } from "react-icons/bs";
import { NavbarContainer, NavItem } from "./NavbarElements";
import { animateScroll as scroll } from "react-scroll";

const Navbar = () => {
  const handelScrollTop = () => {
    scroll.scrollToTop({ duration: 100, delay: 0 });
  };
  return (
    <NavbarContainer>
      <NavItem to="" end onClick={handelScrollTop}>
        <MdHomeFilled />
        خانه
      </NavItem>
      <NavItem to="products" onClick={handelScrollTop}>
        <BsFillBasketFill />
        خدمات
      </NavItem>
      <NavItem to="news" onClick={handelScrollTop}>
        <FaNewspaper />
        اخبار
      </NavItem>
      <NavItem to="profile" onClick={handelScrollTop}>
        <FaUserCircle />
        حساب کاربری
      </NavItem>
    </NavbarContainer>
  );
};

export default Navbar;
