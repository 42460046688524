import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";

const cookies = new Cookies();

const firebaseConfig = {
  apiKey: "AIzaSyCt2t4UdOgbPj6YIo45xWXpr6ctORyH6Js",
  authDomain: "jetup-7659b.firebaseapp.com",
  projectId: "jetup-7659b",
  storageBucket: "jetup-7659b.appspot.com",
  messagingSenderId: "73999594384",
  appId: "1:73999594384:web:c6ac41d27a7d7043f3b915",
  measurementId: "G-BT3N3WP6DK",
};

const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

function getTokenFunction() {
  getToken(messaging, {
    vapidKey:
      "BBPTkUwb0z04J7jo5hFJtyrlMs3nZDYUbWC59TXb09bDz-SSHFVj82omWobC7nqr3lRdW5eUnWxwyCn3e4jTtHo",
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log(currentToken);
        cookies.set("firebase-token", currentToken, {
          path: "/",
          secure: true,
          maxAge: 90000000,
        });
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      window.addEventListener("click", getPermissionsFunction);
    });
}
getTokenFunction();
onMessage(messaging, (payload) => {
  console.log(payload.notification);
  Swal.fire({
    icon: "info",
    title: payload.notification.title,
    text: payload.notification.body,
    confirmButtonText: "بستن",
  });
});
// let number = 1;
function getPermissionsFunction() {
  Notification.requestPermission().then(function (status) {
    if (status === "denied") {
      Swal.fire({
        icon: "error",
        title: "خطا!!",
        text: "برای دریافت اعلانات بر روی 'Allow' ضربه بزنید",
        confirmButtonText: "بستن",
      });
      window.removeEventListener("click", getPermissionsFunction);
    } else if (status === "granted") {
      getTokenFunction();
      window.removeEventListener("click", getPermissionsFunction);
    }
  });
}
