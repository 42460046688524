import styled from "styled-components";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

export const SpeedTestContainer = styled.div`
  background-color: #ffffff;
  box-shadow: #acacac3f 0px 7px 29px 0px;
  padding: 20px;
  border-radius: 20px;
`;
export const SpeedTestTitle = styled.h3`
  position: relative;
  font-size: 1.2rem;
  color: #000;
  margin-bottom: 20px;
  z-index: 1;
`;

export const SpeedTestNumber = styled.h4`
  position: relative;
  top: 5px;
  font-size: 0.9rem;
  text-align: center;
  margin-bottom: 40px;
  span {
    display: inline-block;
    direction: ltr;
  }
`;
export const SpeedTestUl = styled.ul`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px 0 20px;
`;
export const SpeedTestLi = styled.li`
  width: 50%;
  list-style: none;
  margin-bottom: -20px;
`;
export const LoadingIcon = styled(AiOutlineLoading3Quarters)`
  width: 20px;
  height: auto;
  -webkit-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
`;
export const ProgressCircle = styled.div`
  &[role="progressbar"] {
    --size: 7rem;
    --fg: #0165ff;
    --bg: #e9e9e9;
    --pgPercentage: var(--value);
    animation: growProgressBar 3s 1 forwards;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    display: grid;
    place-items: center;
    background: radial-gradient(
        closest-side,
        white 90%,
        transparent 0 99.9%,
        white 0
      ),
      conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0);
    font-family: Helvetica, Arial, sans-serif;
    font-size: calc(var(--size) / 5);
    color: var(--fg);
    transition: all 0.6s ease-in-out;
    margin: 0 auto -30px;
    transform: rotate(270deg);
    clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%);
  }
`;
