import React, { useState } from "react";
import ContentLoader from "react-content-loader";
import { Button } from "../ButtonElements";
import {
  SpeedTestContainer,
  SpeedTestNumber,
  SpeedTestTitle,
  SpeedTestLi,
  SpeedTestUl,
  LoadingIcon,
  ProgressCircle,
} from "./SpeedTestElements";

const SpeedTest = () => {
  const [speedTestDownload, setSpeedTestDownload] = useState("0 Mbps");
  const [chartDownload, setChartDownload] = useState(0);
  const [speedTestUpload, setSpeedTestUpload] = useState("0 Mbps");
  const [chartUpload, setChartUpload] = useState(0);
  const [loading, setLoading] = useState(false);

  let imageAddr = `https://demo.skycube.net/jqspeedtest/testimage.jpg?_=${Math.random()}`;
  let downloadSize = 4796123;

  const handelDownloadSpeedTest = (DownInterval, UpInterval) => {
    let startTime, endTime;
    let download = new Image();
    download.onload = () => {
      endTime = new Date().getTime();
      showResults();
    };

    download.onerror = () => {
      setSpeedTestDownload("دوباره تلاش کنید");
      setChartDownload(0);
      setSpeedTestUpload("دوباره تلاش کنید");
      setChartUpload(0);
      setLoading(false);
      clearInterval(DownInterval);
      clearInterval(UpInterval);
    };

    startTime = new Date().getTime();
    download.src = imageAddr;

    const showResults = () => {
      let duration = (endTime - startTime) / 1000;
      let bitsLoaded = downloadSize * 8;
      let speedBps = (bitsLoaded / duration).toFixed(2);
      let speedKbps = (speedBps / 1024).toFixed(2);
      let speedMbps = (speedKbps / 1024).toFixed(2);
      clearInterval(DownInterval);
      clearInterval(UpInterval);
      setChartDownload(speedMbps * 2.2);
      setSpeedTestDownload(`${speedMbps} Mbps`);
      handelUploadSpeedTest();
    };
  };

  const handelUploadSpeedTest = () => {
    function checkUploadSpeed(iterations, update) {
      let average = 0,
        index = 0;
      check();

      function check() {
        let xhr = new XMLHttpRequest(),
          url = "?cache=" + Math.floor(Math.random() * 10000),
          data = getRandomString(1),
          startTime,
          speed = 0;
        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4) {
            speed = Math.round(1024 / ((new Date() - startTime) / 1000));
            average === 0
              ? (average = speed)
              : (average = Math.round((average + speed) / 2));
            update(speed, average);
            index++;
            if (index === iterations) {
            }
          }
        };
        xhr.open("POST", url, true);
        startTime = new Date();
        xhr.send(data);
      }

      function getRandomString(sizeInMb) {
        let chars =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~!@#$%^&*()_+`-=[]{}|;':,./<>?",
          iterations = sizeInMb * 1024 * 1024,
          result = "";
        for (let index = 0; index < iterations; index++) {
          result += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return result;
      }
    }

    checkUploadSpeed(30, function (speed) {
      setChartUpload((speed / 1024).toFixed(2) * 2.2);
      setSpeedTestUpload(`${(speed / 1024).toFixed(2)} Mbps`);
      setLoading(false);
      // console.clear();
    });
  };
  const handelSpeedTest = () => {
    setChartDownload(0);
    setChartUpload(0);
    const DownInterval = setInterval(() => {
      let randNumDown = Math.trunc(Math.random() * (12 - 3 + 1) + 3);
      setChartDownload(randNumDown);
    }, 700);
    const UpInterval = setInterval(() => {
      let randNumUp = Math.trunc(Math.random() * (16 - 2 + 1) + 3);
      setChartUpload(randNumUp);
    }, 700);
    setSpeedTestDownload(`0 Mbps`);
    setSpeedTestUpload(`0 Mbps`);
    setLoading(true);
    handelDownloadSpeedTest(DownInterval, UpInterval);
  };
  return (
    <SpeedTestContainer>
      <SpeedTestTitle>تست سرعت اینترنت</SpeedTestTitle>
      <SpeedTestUl>
        <SpeedTestLi>
          {/* <svg
            className="circle-chart"
            viewBox="0 0 39 37"
            xmlns="http://www.w3.org/2000/svg"
          >
            <SpeedTestCircle
              stroke="#eeeeee"
              strokeWidth="1.5"
              fill="none"
              cx="20"
              cy="16.91549431"
              r="15.91549431"
            ></SpeedTestCircle>
            <SpeedTestCircle
              className="circle-chart-circle"
              strokeWidth="1.5"
              stroke="#0165ff"
              strokeDasharray={`${chartDownload},100`}
              fill="none"
              cx="20"
              cy="16.91549431"
              r="15.91549431"
            ></SpeedTestCircle>
          </svg> */}
          <ProgressCircle
            role="progressbar"
            aria-valuenow={chartDownload}
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ "--value": `${chartDownload}` }}
          />
          <SpeedTestNumber>
            دانلود :{" "}
            <span>
              {loading ? (
                <ContentLoader
                  width="60px"
                  height="14px"
                  viewBox="0 0 170 30"
                  backgroundColor="#8686864e"
                  foregroundColor="#ecebebc7"
                  style={{
                    position: "relative",
                    zIndex: 5,
                    verticalAlign: "top",
                    display: "inline",
                    top: "-1.5px",
                    RIGHT: "1.5px",
                  }}
                >
                  <rect x="0" y="0" rx="0" ry="0" width="170" height="35" />
                </ContentLoader>
              ) : (
                speedTestDownload
              )}
            </span>
          </SpeedTestNumber>
        </SpeedTestLi>
        <SpeedTestLi>
          {/* <svg
            className="circle-chart"
            viewBox="0 0 39 37"
            xmlns="http://www.w3.org/2000/svg"
          >
            <SpeedTestCircle
              stroke="#eeeeee"
              strokeWidth="1.5"
              fill="none"
              cx="20"
              cy="16.91549431"
              r="15.91549431"
            ></SpeedTestCircle>
            <SpeedTestCircle
              className="circle-chart-circle"
              strokeWidth="1.5"
              stroke="#0165ff"
              strokeDasharray={`${chartUpload},100`}
              fill="none"
              cx="20"
              cy="16.91549431"
              r="15.91549431"
            ></SpeedTestCircle>
          </svg> */}

          <ProgressCircle
            role="progressbar"
            aria-valuenow={chartUpload}
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ "--value": `${chartUpload}` }}
          />

          <SpeedTestNumber>
            آپلود :{" "}
            <span>
              {loading ? (
                <ContentLoader
                  width="60px"
                  height="14px"
                  viewBox="0 0 170 30"
                  backgroundColor="#8686864e"
                  foregroundColor="#ecebebc7"
                  style={{
                    position: "relative",
                    zIndex: 5,
                    verticalAlign: "top",
                    display: "inline",
                    top: "-1.5px",
                    RIGHT: "1.5px",
                  }}
                >
                  <rect x="0" y="0" rx="0" ry="0" width="170" height="35" />
                </ContentLoader>
              ) : (
                speedTestUpload
              )}
            </span>
          </SpeedTestNumber>
        </SpeedTestLi>
      </SpeedTestUl>
      <Button onClick={handelSpeedTest} disabled={loading}>
        {loading ? <LoadingIcon /> : "شروع"}
      </Button>
    </SpeedTestContainer>
  );
};

export default SpeedTest;
