import React from "react";
import {
  Expiration,
  ExpirationContent,
  ExpirationContentWave,
  ExpirationInfo,
  ExpirationInfoChild,
  ExpirationName,
  ExpirationTime,
  ExpirationTitle,
} from "./ExpirationElements";
import {
  BsArrowDownShort,
  BsArrowUpShort,
  BsFillCalendar2DateFill,
} from "react-icons/bs";
import LazyLoadText from "../LazyLoadText";

const ExpirationComponents = ({
  expire = null,
  accountName = null,
  download = null,
  upload = null,
}) => {
  return (
    <Expiration>
      <ExpirationTitle>
        <BsFillCalendar2DateFill /> نزدیک ترین سر رسید
      </ExpirationTitle>
      <ExpirationContent>
        <ExpirationTime>
          اعتبار سرویس : {expire === null ? <LazyLoadText /> : expire}
        </ExpirationTime>
        <ExpirationName>
          نام اکانت : {accountName === null ? <LazyLoadText /> : accountName}
        </ExpirationName>
        <ExpirationInfo>
          <ExpirationInfoChild>
            <BsArrowDownShort />
            {download === null ? <LazyLoadText /> : download}
          </ExpirationInfoChild>
          <ExpirationInfoChild>
            <BsArrowUpShort />
            {upload === null ? <LazyLoadText /> : upload}
          </ExpirationInfoChild>
        </ExpirationInfo>
      </ExpirationContent>
      <ExpirationContentWave
        fill="#000f7e41"
        paused={false}
        options={{
          height: 30,
          amplitude: 20,
          speed: 0.25,
          points: 4,
        }}
      />
    </Expiration>
  );
};

export default ExpirationComponents;
