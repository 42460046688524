import React, { useEffect, useState } from "react";
import Menu from "../Menu";
import Cookies from "universal-cookie";
import PullToRefresh from "react-simple-pull-to-refresh";
import axios from "axios";
import {
  Productcontainer,
  ProductItems,
  ProductTab,
  ProductTabs,
} from "./ProductElements";
import ProductItem from "../ProductItem";
import ContentLoader from "react-content-loader";
import { HomeError } from "../Home/HomeElements";

const cookies = new Cookies();

const Products = ({
  productsState,
  SetProductsState,
  dashboardState,
  SetDashboardState,
  SetConnectionState,
  notifactionCount,
  setNotifactionCount,
  activeIndex,
  setActiveIndex,
  category,
  setCategory,
  tokenValidation,
  notifactionState,
  setNotifactionState,
  handelLogout,
}) => {
  const [state, setState] = useState(productsState);
  const [dashNumber, setDashNumber] = useState(0);
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    document.title = "محصولات";
    handelConnections();
    if (state === false) {
      handleRequest();
    }
  });
  const handelConnections = () => {
    if (
      dashboardState !== false &&
      dashNumber === 0 &&
      dashboardState !== null
    ) {
      setDashNumber(1);
      const dashboardItems = { data: [...dashboardState.data] };
      SetDashboardState({ data: [...dashboardItems.data.slice(0, 20)] });
      SetConnectionState({ data: [...dashboardItems.data.slice(0, 20)] });
    }
  };
  const handleRequest = () => {
    setState(false);
    const categoriesOptions = {
      method: "GET",
      url: "https://jetup.app/api/product/list",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${cookies.get("token")}`,
      },
    };
    axios
      .request(categoriesOptions)
      .then((response) => {
        console.log(response.data);
        if (response.status === 200) {
          setState({ data: [...response.data] });
          SetProductsState({ data: [...response.data] });
        }
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 500) {
            setState(null);
            SetProductsState(false);
            setErrorText(
              "مشکلی در دریافت اطلاعات پیش آمده ، دقایقی دیگر مجددا تلاش کنید."
            );
          }
          if (error.response.status === 401) {
            handelLogOutMenu();
          }
        }
        if (error.message === "Network Error") {
          setState(null);
          SetProductsState(false);
          setErrorText("لطفا از اتصال اینترنت خود مطمئن شوید.");
        }
      });
  };
  const handleSwitchTabs = (e) => {
    setCategory(Number(e.target.getAttribute("data-tab-num")));
    setActiveIndex(Number(e.target.getAttribute("data-tab-num")));
  };
  const handelLogOutMenu = () => {
    cookies.remove("token", { path: "/" });
    let IntervalChecking = setInterval(() => {
      if (typeof handelLogout === "function") {
        handelLogout();
        clearInterval(IntervalChecking);
      } else {
        console.log("handel logout is not function yet :||| ");
      }
    }, 1000);
  };
  return (
    <PullToRefresh
      onRefresh={() => new Promise((resolve) => resolve(handleRequest()))}
      isPullable={true}
      canFetchMore={true}
      pullingContent=""
      maxPullDownDistance={100}
      pullDownThreshold={100}
    >
      <Menu
        notifactionCount={notifactionCount}
        setNotifactionCount={setNotifactionCount}
        tokenValidation={tokenValidation}
        notifactionState={notifactionState}
        setNotifactionState={setNotifactionState}
      />
      <Productcontainer>
        {state === null ? (
          <HomeError>{errorText}</HomeError>
        ) : (
          <>
            {state !== false ? (
              <ProductTabs>
                {state.data.map((e, i) => {
                  const className = activeIndex === i ? "active" : "";
                  return (
                    <ProductTab
                      key={i}
                      className={className}
                      data-tab-num={i}
                      onClick={(el) => {
                        handleSwitchTabs(el);
                      }}
                    >
                      {e.title}
                    </ProductTab>
                  );
                })}
              </ProductTabs>
            ) : (
              <ContentLoader
                width="100%"
                height="65"
                viewBox="0 0 400 65"
                backgroundColor="#d6d6d6bc"
                foregroundColor="#dfdfdfaf"
                style={{
                  position: "relative",
                  zIndex: 5,
                  verticalAlign: "top",
                  display: "block",
                  margin: "0 auto",
                }}
              >
                <rect x="205" y="18" rx="10" ry="10" width="41%" height="45" />
                <rect x="30" y="18" rx="10" ry="10" width="41%" height="45" />
              </ContentLoader>
            )}

            {state !== false ? (
              <ProductItems>
                {state.data[category].products.map((e, i) => (
                  <ProductItem
                    key={i}
                    productTitle={e.title}
                    productDescription={e.description}
                    productEnable={e.enable}
                    productMonthly={e.monthly}
                    productQuarterly={e.quarterly}
                    productSemiannual={e.semiannual}
                    productAnnually={e.annually}
                    productTraffic={e.traffic}
                    ProductItemBold={e.bold === 1 ? true : false}
                    productCountry={e.server.short_code}
                  />
                ))}
              </ProductItems>
            ) : (
              <li style={{ listStyle: "none" }}>
                <ContentLoader
                  width="86%"
                  height="100%"
                  viewBox="0 0 450 800"
                  backgroundColor="#d6d6d6bc"
                  foregroundColor="#dfdfdfaf"
                  style={{
                    position: "relative",
                    zIndex: 5,
                    verticalAlign: "top",
                    display: "block",
                    margin: "0 auto",
                  }}
                >
                  <rect
                    x="0"
                    y="37"
                    rx="25"
                    ry="25"
                    width="100%"
                    height="617"
                  />
                </ContentLoader>
              </li>
            )}
          </>
        )}
      </Productcontainer>
    </PullToRefresh>
  );
};

export default Products;
